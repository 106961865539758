<template>
  <v-container
    id="policy-details"
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in mainTabs"
        :key="`tab-${i}`"
        :to="tab.to"
      >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>
    </base-material-tabs>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />

    <router-view
      v-else-if="shouldHideProfile"
      :policy="policy"
      @updateGeneral="getPolicy"
    />

    <v-row v-else>
      <v-col
        cols="12"
        md="8"
      >
        <router-view
          :policy="policy"
          @updateGeneral="getPolicy"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <profile :policy="policy" />

        <premium-and-fees
          :policy-cost="policy.policy_cost"
          @update-policy="getPolicy"
        />

        <agency />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'

  export default {
    components: {
      Profile: () => import('../../components/policy/Profile'),
      PremiumAndFees: () => import('../../components/policy/PremiumAndFees'),
      Agency: () => import('../../customers/details/Agency'),
    },

    data: () => ({
      mainTab: 0,
      mainTabs: [
        { text: 'General', icon: 'mdi-numeric', to: 'general' },
        { text: 'Address', icon: 'mdi-map-marker', to: 'address' },
        { text: 'Files', icon: 'mdi-file', to: 'files' },
      ],
      loading: false,
      policy: {},
    }),

    computed: {
      shouldHideProfile () {
        const path = this.$route.path
        return path.endsWith('files')
      },
    },

    mounted () {
      this.getPolicy()
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getPolicy () {
        this.loading = true
        try {
          const response = await axios.get('policies/policy/' + this.$route.params.id)
          this.policy = {
            ...response.data,
            policy_status_types: response.data.policy_status_types.map(statusType => statusType.status_type_policy_id),
          }
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loading = false
      },
    },
  }
</script>

<style lang="sass">
#policy-details
  .profile-photo
    border-radius: 50%
    width: 128px
    min-width: 128px
    height: 128px
  .profile-section
    .v-card--material__heading
      display: flex
      justify-content: center
      align-items: center
      border-radius: 50%
      width: 128px
      min-width: 128px
      height: 128px
  .v-speed-dial__list
    margin-left: 6px
</style>
